( function() {
  'use strict';

  const apiUrl = 'https://api.eden.csw.backofficeprojectdata.com/';
  const images = 'https://eden.csw.backofficeprojectdata.com/';
  angular
    .module( 'app' )
    .constant( 'config', {
      colors: {
        danger: $('<div>').appendTo('body').addClass('bg-danger').css('background-color')
      },
      endpoints: {
          attribute: apiUrl + 'v1.0/csentityattributes',
          dependent: apiUrl + 'v1.0/csentitychildren',
          entityObject: apiUrl + 'v1.0/csentityobjects',
          login: apiUrl + 'oauth/v2/token',
          lov: apiUrl + 'v1.0/cslovs',
          lovMeter: apiUrl + 'v1.0/cslovmeters',
          value: apiUrl + 'v1.0/cslovvalues',
          user: apiUrl + 'v1.0/users',
          work: apiUrl + 'v1.0/csworks',
          settings: apiUrl + 'v1.0/configurations',
          markerImages: images + 'images/markers',
          group: apiUrl + 'v1.0/groups',
          incms: apiUrl + 'v1.0/incms'
      },

      xmlData: {
        'clientVersion' : '4.5.18.42',
        'language' : 'ES',
        'country' : 'es'
      },
      auth: {
        'clientId': '1_3efot0teuk4kckscw8kw8oog004so88ko0g4c800k0kc4skswg',
        'clientSecret': '2l21m7zsa7uow0ok0sg4s0ggccocc000go0s8csgssgs4wsw4w'
      },
      listLimit: 20
    } );
} )();
